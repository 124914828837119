module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-9c33763778/4/C:/Users/Himadu Sirimanna/AppData/Local/Yarn/Berry/cache/gatsby-plugin-manifest-npm-5.14.0-8a89b421cf-10.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ANSI Global","short_name":"ANSI","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0eae4ebf9b5787c9a5de77371e635ee3"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-nprogress-virtual-4d4144758c/4/C:/Users/Himadu Sirimanna/AppData/Local/Yarn/Berry/cache/gatsby-plugin-nprogress-npm-5.14.0-80ce602206-10.zip/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":true},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-2e6399e0de/4/C:/Users/Himadu Sirimanna/AppData/Local/Yarn/Berry/cache/gatsby-plugin-google-gtag-npm-5.14.0-7fc489a55d-10.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YL59MCGMDT"],"gtagConfig":{}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-ee91d32225/4/C:/Users/Himadu Sirimanna/AppData/Local/Yarn/Berry/cache/gatsby-plugin-offline-npm-6.14.0-87805d241e-10.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-0983909a70/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
